<template>
  <a-modal
    title="编辑岗位需求"
    width="1200px"
    v-model="visible"
    @cancel="hide"
    @ok="handleSubmit"
    :okButtonProps="{
      props: {
        loading,
      },
    }"
  >
    <div class="modal-form-wrapper">
      <div class="enable-box">
        <div class="enable-box-item">项目部：{{ model.projectDepartmentName }}</div>
        <div class="enable-box-item">项目名称：{{ model.projectName }}</div>
        <div class="enable-box-item">人员类型：{{ model.type == 1 ? '技能人员' : '技术人员' }}</div>
        <div class="enable-box-item">{{ model.type == 1 ? '工种' : '专业' }}：{{ model.positionName }}</div>
      </div>
      <a-form-model ref="form" :model="model" :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 17 }">
        <a-row>
          <a-col :span="24">
            <a-form-model-item
              label="基本技能"
              prop="baseSkillIds"
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 21 }"
            >
              <a-checkbox-group v-model="model.baseSkillIds">
                <a-checkbox v-for="item in baseSkillDtoList" :key="item.name" :value="item.id.toString()">{{
                  item.name
                }}</a-checkbox>
              </a-checkbox-group>
            </a-form-model-item>
          </a-col>
          <a-col :span="24" v-if="model.type == 1">
            <a-form-model-item
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 21 }"
              label="专业技能"
              prop="professionalSkillIds"
              :rules="professionalSkillDtoList.length ? { validator: validProfessionalSkillIds } : null"
              :class="professionalSkillDtoList.length ? 'required' : ''"
            >
              <a-checkbox-group v-model="model.professionalSkillIds">
                <a-checkbox v-for="item in professionalSkillDtoList" :key="item.name" :value="item.id.toString()">{{
                  item.name
                }}</a-checkbox>
              </a-checkbox-group>
            </a-form-model-item>
          </a-col>
          <a-col :span="24" v-if="model.type == 2">
            <a-form-model-item
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 21 }"
              label="从业资格证"
              prop="qualificationCertificateIds"
              :rules="{ validator: validQualificationCertificateIds }"
              class="required"
            >
              <a-checkbox-group v-model="model.qualificationCertificateIds">
                <a-checkbox v-for="item in requirements" :key="item.name" :value="item.id">{{ item.name }}</a-checkbox>
              </a-checkbox-group>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="项目经历" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }" prop="experience">
              <a-radio-group name="radioGroup" v-model="model.experience">
                <a-radio :value="1">30万机组以下</a-radio>
                <a-radio :value="2">30-60万机组</a-radio>
                <a-radio :value="3">100万及以上机组</a-radio>
                <a-radio :value="0">不限</a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item
              label="技能等级证"
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 21 }"
              prop="skillLevelCertificate"
            >
              <a-radio-group name="radioGroup" v-model="model.skillLevelCertificate">
                <a-radio :value="1">初级</a-radio>
                <a-radio :value="2">中级</a-radio>
                <a-radio :value="3">高级</a-radio>
                <a-radio :value="-1">特殊工种证书</a-radio>
                <a-radio :value="0">不限</a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="招聘人数" prop="number">
              <a-input-number
                style="width: 120px"
                :max="99999999"
                :min="1"
                v-model="model.number"
                :formatter="(value) => `${value}`"
              />
              人
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="薪资标准" prop="salaryType" :autoLink="false" ref="salary" required>
              <a-input-number
                style="width: 100px"
                :max="9999999999"
                :min="1"
                v-model="model.startSalary"
                @blur="
                  () => {
                    $refs.salary.onFieldBlur()
                  }
                "
                @change="
                  () => {
                    $refs.salary.onFieldChange()
                  }
                "
                :formatter="(value) => `${value}`"
              />
              -
              <a-input-number
                style="width: 100px"
                :max="9999999999"
                :min="1"
                v-model="model.endSalary"
                @blur="
                  () => {
                    $refs.salary.onFieldBlur()
                  }
                "
                @change="
                  () => {
                    $refs.salary.onFieldChange()
                  }
                "
                :formatter="(value) => `${value}`"
              />
              <a-select style="width: 100px; margin-left: 10px" v-model="model.salaryType">
                <a-select-option v-for="item in salaryTypeList" :value="item" :key="item">{{ item }} </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="食宿" prop="boardLodging">
              <a-radio-group name="radioGroup" v-model="model.boardLodging">
                <a-radio :value="0">包吃</a-radio>
                <a-radio :value="1">包住</a-radio>
                <a-radio :value="2">包吃住</a-radio>
                <a-radio :value="3">不提供</a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="路费" prop="toll">
              <a-radio-group name="radioGroup" v-model="model.toll">
                <a-radio :value="0">单程</a-radio>
                <a-radio :value="1">双程</a-radio>
                <a-radio :value="2">不提供</a-radio>
                <a-radio :value="3">来程算工资</a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="工作地点" prop="place">
              <a-cascader
                :options="areaOption"
                v-model="model.place"
                :fieldNames="{
                  label: 'district',
                  value: 'district',
                  children: 'children',
                }"
                placeholder="请选择工作地点"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="工作时间" prop="workTime">
              <a-range-picker value-format="YYYY-MM-DD" v-model="model.workTime" :disabledDate="disabledDate" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="岗位职责" prop="duty">
              <a-textarea
                :maxLength="200"
                v-model="model.duty"
                placeholder="例：
1，负责***产品工艺系统流程设计；
2，负责***产品设计过程中系统设备选型；
3，负责***产品工程应用中的施工图设计"
                :rows="4"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="职位描述" prop="jobDescription">
              <a-textarea
                :maxLength="200"
                v-model="model.jobDescription"
                placeholder="例：
1，负责***产品工艺系统流程设计；
2，负责***产品设计过程中系统设备选型；
3，负责***产品工程应用中的施工图设计"
                :rows="4"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import { getById } from '@/api/recruitUse'
import {
  employmentEmploybaseSkill,
  employmentEmploypublish,
  getAreaTree
} from '@/api/workDemand'
import moment from 'moment'
import { Cascader } from 'ant-design-vue'

export default {
  name: 'ModalForm',
  components: {
    'a-cascader': Cascader
  },
  data () {
    const validSalary = (rule, value, callback) => {
      const { startSalary, endSalary } = this.model
      if (!startSalary || !endSalary) {
        callback(new Error('请填写完整薪资标准'))
      } else {
        callback()
      }
    }
    const validTime = (rule, value, callback) => {
      const { workTime = [] } = this.model
      const [start, end] = workTime
      // console.log(start, end, )
      if (start && end && moment(start).add(1, 'year').format('YYYY-MM-DD') <= end) {
        callback(new Error('工作时间跨度不得超过一年'))
      } else {
        callback()
      }
    }
    return {
      model: {},
      visible: false,
      loading: false,
      rules: {
        baseSkillIds: { required: true, message: '请选择基本技能', trigger: 'change' },
        experience: { required: true, message: '请选择项目经历', trigger: 'change' },
        skillLevelCertificate: { required: true, message: '请选择技能等级证', trigger: 'change' },
        number: { required: true, message: '请输入招聘人数', trigger: 'blur' },
        salaryType: [{ validator: validSalary }],
        boardLodging: { required: true, message: '请选择食宿', trigger: 'change' },
        toll: { required: true, message: '请选择路费', trigger: 'change' },
        place: { required: true, message: '请选择工作地点', trigger: 'change' },
        workTime: [{ required: true, message: '请选择工作时间', trigger: 'change' }, { validator: validTime }],
        duty: { required: true, message: '请输入岗位职责', trigger: 'blur' },
        jobDescription: { required: true, message: '请输入职位描述', trigger: 'blur' }
      },
      salaryTypeList: ['元/天', '元/小时', '元/月'],
      requirements: [
        { name: '安全员', id: '1' },
        { name: '建造师', id: '2' },
        { name: '不限', id: '0' }
      ],
      areaOption: [],
      baseSkillDtoList: [],
      professionalSkillDtoList: []
    }
  },
  created () {
    this.initModalData()
  },
  methods: {
    show (id) {
      this.visible = true
      if (id) {
        getById(id).then((res) => {
          const model = this.initModel(res.data)
          this.$set(this, 'model', model)
          this.getDictData()
        })
      }
    },
    initModel (model) {
      const salaryType = this.salaryTypeList.find((item) => model.salary.includes(item))
      const [startSalary, endSalary] = model.salary.split(salaryType)[0].split('-')
      return {
        ...model,
        workTime: [
          moment(model.workStartTime || model.projectStartTime).format('YYYY-MM-DD'),
          moment(model.workEndTime || model.projectEndTime).format('YYYY-MM-DD')
        ],
        salaryType,
        startSalary,
        endSalary,
        place: model.place.split('-'),
        baseSkillIds: model.baseSkillId.split(','),
        professionalSkillIds: model.professionalSkillId.split(',')
      }
    },
    hide () {
      this.visible = false
    },
    disabledDate (current) {
      if (current.format('YYYY-MM-DD') <= moment(moment().subtract(1, 'month')).format('YYYY-MM-DD')) {
        return true
      }
      return false
    },
    initModalData () {
      getAreaTree() // 城市
        .then((res) => {
          this.areaOption = res
        })
    },
    getDictData () {
      employmentEmploybaseSkill({ type: this.model.type, positionId: this.model.positionId }).then((res) => {
        if (res.data) {
          this.baseSkillDtoList = res.data.baseSkillDtoList || []
          this.professionalSkillDtoList = res.data.professionalSkillDtoList || []
          if (!res.data.professionalSkillDtoList.length) {
            this.$refs.form.clearValidate('professionalSkillIds')
          }
        }
      })
    },
    getLabel (index) {
      const data = this.form.demandInfoList[index].type
      return data === '2' ? '专业' : '工种'
    },
    validProfessionalSkillIds (rule, value, callback) {
      if (this.model.type === '2') {
        callback()
      } else {
        if (!value || !value.length) {
          callback(new Error('请选择专业技能'))
        }
        callback()
      }
    },
    validQualificationCertificateIds (rule, value, callback) {
      if (this.model.type === '1') {
        callback()
      } else {
        if (!value || !value.length) {
          callback(new Error('请选择从业资格证'))
        }
        callback()
      }
    },
    handleFormatModel () {
      return {
        projectDepartmentId: this.model.projectDepartmentId,
        projectId: this.model.projectId,
        demandInfoList: [this.model].map((item) => {
          // eslint-disable-next-line prefer-const
          let { startSalary, endSalary, salaryType } = item
          const [workStartTime, workEndTime] = item.workTime
          if (endSalary < startSalary) {
            ;[startSalary, endSalary] = [endSalary, startSalary]
          }
          return {
            ...item,
            place: item.place.join('-'),
            salary: `${startSalary}-${endSalary}${salaryType}`,
            workStartTime,
            workEndTime
          }
        })
      }
    },
    handleSubmit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          const model = this.handleFormatModel()
          employmentEmploypublish(model)
            .then(() => {
              this.$message.success('编辑成功')
              this.$emit('success')
              this.hide()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    }
  }
}
</script>

<style lang="less">
.modal-form-wrapper {
  .enable-box {
    height: 80px;
    width: 100%;
    border: 1px solid #4f98ff;
    background-color: #c3dcff;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    &-item {
      width: 50%;
      color: #222222;
      font-weight: 550;
      font-size: 16px;
      display: flex;
      align-items: center;
      padding-left: 30px;
    }
  }
  .required {
    .ant-form-item-label {
      label {
        position: relative;
        &::before {
          display: inline-block;
          margin-right: 4px;
          color: #f5222d;
          font-size: 14px;
          font-family: SimSun, sans-serif;
          line-height: 1;
          content: '*';
        }
      }
    }
  }
}
</style>
